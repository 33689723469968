<template>
  <div id="healthcare">
    <v-carousel
      interval="4000"
      hide-delimiters
      :show-arrows="false"
      height="550"
    >
      <div class="container_">
        <v-carousel-item
          src="../../assets/images/health-bg 1.jpg"
          class="image"
        >
          <v-row class="fill-height" align="center" justify="center">
            <div class="overlay">
              <v-col
                :style="
                  $vuetify.breakpoint.xs
                    ? 'padding:0 3rem'
                    : 'padding:0 5rem 6rem'
                "
              >
                <p class="white--text mb-8 head">
                  Agriculture
                </p>
                <h1 class="font-weight-bold white--text">
                  Preserving the integrity of <br />
                  agricultural commodities.
                </h1>
              </v-col>
            </div>
          </v-row>
        </v-carousel-item>
      </div>
    </v-carousel>
    <div>
      <v-col class="d-flex justify-center pt-12">
        <h4 class="text--center">
          In Nigeria, research reveals that the lack of access to cold chain
          systems and reliable power supply required to power them results in
          40% of post-harvest losses.
        </h4>
      </v-col>
      <v-row
        :style="
          $vuetify.breakpoint.xs
            ? 'overflow: hidden; padding:3rem 1rem'
            : 'padding: 4rem 0 4rem 4rem;overflow: hidden;'
        "
      >
        <v-col cols="12" sm="6" :class="$vuetify.breakpoint.mdUp ? 'pa-8' : ''">
          <article>
            <h2 class="pb-8">
              Innovative solution for perishable <br />
              agricultural products.
            </h2>
            <p class="">
              Most of our agricultural perishable products are temperature
              sensitive and require to be kept within the cold chain from the
              moment they are harvested to when they are stored, transported,
              and distributed to the final consumer. <br />
              <br />
              Gricd provides a solution to ensure across the entire supply chain
              that all products are optimally stored and shipped. Simplifying
              the temperature and humidity monitoring process, Gricd offers
              uniquely easy-to-use sensors that enable the quality of
              agricultural and fresh produce to be maintained in storage or
              during transit. Using our solution helps you comply with the Good
              Distribution Practice (GDP) in every climate. <br />
              <br />
              The Gricd temperature and humidity monitoring solution helps
              maximize the profitability of perishables in storage or on the
              move, and also helps in minimizing the risk of damage to products
              while extending their shelf life.
            </p>
          </article>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class=" dashboard"
          :class="$vuetify.breakpoint.xs ? 'pl-3' : 'pl-16'"
          ><img src="../../assets/images/Rectangle 1185@3x.png" alt=""
        /></v-col>
      </v-row>
      <!-- <div class="img-size" v-if="$vuetify.breakpoint.smAndUp">
        <img width="100%" src="../../assets/images/Group 1430@3x.png" alt="" />
      </div> -->

      <!-- apparatus equipment section -->
      <div class="py-12" style="background: rgba(242, 245, 247, 0.46);">
        <div class="top-apparatus text-center">
          <h6>The Problem</h6>
          <h2 class="py-6 px-8">Cooling challenge for agriculture</h2>
        </div>

        <v-row class="px-12 py-5" style="justify-content:space-evenly">
          <div class="apparatus-card" v-for="(m, i) in icon" :key="i">
            <img
              :src="m.img"
              style="height: 4rem;max-width: 4rem;"
              alt=""
              class="mb-4"
            />
            <p class="p1">{{ m.p1 }}</p>
            <p class="p2">{{ m.p2 }}</p>
            <p class=" text-center">
              {{ m.content }}
            </p>
          </div>
        </v-row>
      </div>

      <v-row
        :style="$vuetify.breakpoint.xs ? 'padding:4rem 1rem' : 'padding:4rem'"
      >
        <v-col cols="12" sm="6" class="prod-container">
          <h2>Why Use Gricd Cold Boxes?</h2>
          <p>
            IoT enabled 15 liter active cooling box to maintain stable internal
            temperature of up to -20° C for up to 24 hours.
          </p>
          <img src="../../assets/images/mote-50 1.png" alt="" width="65%" />
        </v-col>
        <v-col cols="12" sm="6" class="right_">
          <img src="../../assets/images/Group 1402.png" alt="" />
          <div>
            <div class="right-div">
              <h5>Reduce product losses</h5>
              <p>
                Gricd cold boxes will significantly help reduce post-harvest
                product loss as they are designed with active cooling technology
                that maintains product preset storage temperatures.
              </p>
            </div>
            <div class="right-div">
              <h5>Get ahead of competitors</h5>
              <p class="btm-p">
                With a reliable cooling system, a wider market and reach can be
                explored and serviced
              </p>
            </div>
            <div class="right-div">
              <h5>Trackable system</h5>
              <p>
                A decrease on food losses with an increase in market reach would
                significantly increase business revenue.
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import ic1 from '../../assets/about icon/Group 1422 (2).png';
import ic2 from '../../assets/about icon/Group 1419 (1).png';
import ic3 from '../../assets/about icon/Group 1421 (3).png';

export default {
  name: 'Agriculture',
  data: () => ({
    icon: [
      {
        img: ic1,
        p1: 'Food Wastage',
        // p2: 'Plasma Sample',
        content:
          'Lack of proper cold chain equipment in developing countries leads to large post-harvest losses. Gricd cold boxes can be used to reduce food wastage and losses.',
      },
      {
        img: ic2,
        p1: 'Poor Market Reach',
        // p2: 'Blood Sample',
        content:
          'Without effective cooling system that can preserve perishable food over long periods and distances, a wider market reach can not be explored or serviced.',
      },
      {
        img: ic3,
        p1: 'Reduced Earnings',
        // p2: 'Vaccine vials',
        content:
          'Low income farmers who do not have access to proper cold chain equipment loose revenue and investments due to post harvest losses.',
      },
    ],
  }),
  metaInfo() {
    return {
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: 'Home',
                item: 'https://gricd.com/',
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: 'Cold Chain Application',
                item: 'https://gricd.com/cold-chain-application/agriculture',
              },
            ],
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
#healthcare {
  .img-bcgrd {
    height: 50rem;
    width: 100%;
  }
  background: #fdfeff;
  h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 180%;
    text-align: center;
    color: #c69d6d;
    width: 50%;
    @media (max-width: 767px) {
      width: 100%;
      text-align: left;
    }
  }
  p {
    font-style: normal;
    font-weight: 300;
    font-size: 0.9rem;
    color: #141414;
  }
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 140%;
    color: #212529;
    @media (max-width: 1024px) {
      font-size: 30px;
    }
  }
  .right_ {
    display: flex;
    column-gap: 2rem;
    padding: 0 5rem;
    img {
      height: 19rem;
      transform: rotate(180deg);
    }
    @media (max-width: 1024px) {
      padding: 3rem 1rem;
      column-gap: 1rem;
      img {
        height: 19rem;
      }
    }
  }
  h5 {
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    padding-bottom: 1rem;
    color: #212529;
  }
  .right-div p {
    margin-bottom: 10%;
    @media (max-width: 1024px) {
      // margin-bottom: 7%;
      margin-bottom: 2rem;
    }
  }
  .prod-container {
    padding: 0.7rem 9rem 0.7rem 4rem;
    h2 {
      padding-bottom: 2rem;
    }
    p {
      padding-bottom: 3rem;
    }
    @media (max-width: 1024px) {
      padding: 0.7rem 1rem;
      p {
        padding-bottom: 1rem;
      }
    }
  }
  .btm-p {
    margin-bottom: 25%;
    @media (max-width: 1024px) {
      margin-bottom: 2.8rem !important;
    }
  }
  .small-p {
    @media (max-width: 1024px) {
      font-size: 12px;
    }
  }
  .dashboard {
    display: flex;
    flex-flow: row;
    align-items: center;
    img {
      height: 32rem;
      background: #ffffff;
      box-shadow: 2px 2px 0px rgba(255, 255, 255, 0.2),
        -2px -2px 0px rgba(255, 255, 255, 0.6);
      border-radius: 30px 0 0 30px;
      border: solid 5px #edf3ff;
    }
  }
  .img-size {
    padding: 0 10rem;
    background: rgba(242, 245, 247, 0.46);
    @media (max-width: 1024px) {
      padding: 3rem 5rem;
    }
  }

  p.head {
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    letter-spacing: 1.1px;
    color: #141414;
  }

  @media (max-width: 1290px) and (min-width: 770px) {
    .right-div p {
      margin-bottom: 2rem;
    }

    .prod-container {
      padding: 0.7rem 3rem 0.7rem 3rem;
    }
    .btm-p {
      margin-bottom: 2.9rem !important;
    }

    h1 {
      font-size: 2.4rem;
    }
    p.head {
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      letter-spacing: 1.1px;
      color: #141414;
    }
  }

  .container_ {
    position: relative;
  }

  .overlay {
    position: absolute;
    bottom: 0rem;
    background: black;
    background: rgba(0, 0, 0, 0.67);
    width: 100%;
    transition: 0.5s ease;
    opacity: 1;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    h2 {
      color: white;
    }
  }

  .container_ .overlay {
    opacity: 1;
  }

  .apparatus-card {
    width: 18rem;
    // border: solid;
    height: 15rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
  h6 {
    font-size: 1rem;
    color: #28a84a;
    font-weight: 500;
  }
  .p1 {
    font-size: 1rem;
    font-weight: 500;
  }
  .p2 {
    font-size: 1rem;
    font-weight: 500;
    color: #28a84a;
  }
}
</style>
